import Immutable from "seamless-immutable";
import * as types from "./actionTypes";

const initialState = Immutable({
  allHelioCores: [],
  cognitoUser: undefined,
  editMode: false,
  newCredentialsDialogOpen: false,
  deleteHelioCoreDialogOpen: false,
  addingHeliocore: false
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_ALL_HELIOCORES:
      return state.merge({
        allHelioCores: action.heliocores
      });
    case types.ADD_NEW_HELIOCORE:
      return state.merge({
        allHelioCores: [...state.allHelioCores, action.heliocore],
        addingHeliocore: false
      });
    case types.TOGGLE_EDIT_MODE:
      return state.merge({
        editMode: !state.editMode
      });
    case types.SET_EDIT_MODE_TO_FALSE:
      return state.merge({
        editMode: false
      });
    case types.UPDATE_HELIOCORE:
      return state.merge({
        allHelioCores: Object.assign([...state.allHelioCores], {
          [state.allHelioCores.findIndex(
            element => element.uuid === action.updatedHelioCore.uuid
          )]: action.updatedHelioCore
        }),
        editMode: !state.editMode
      });
    case types.DELETE_HELIOCORE:
      return state.merge({
        allHelioCores: state.allHelioCores.filter(
          helioCore => helioCore.uuid !== action.deletedHelioCore.uuid
        ),
        deleteHelioCoreDialogOpen: false
      });
    case types.TOGGLE_NEW_CREDENTIALS_DIALOG:
      return state.merge({
        newCredentialsDialogOpen: !state.newCredentialsDialogOpen
      });
    case types.TOGGLE_DELETE_HELIOCORE_DIALOG:
      return state.merge({
        deleteHelioCoreDialogOpen: !state.deleteHelioCoreDialogOpen
      });
    case types.GET_NEW_HELIOCORE_CREDENTIALS:
      return state.merge({
        cognitoUser: action.cognito_user
      });
    case types.DELETE_HELIOCORE_CREDENTIALS:
      return state.merge({
        cognitoUser: undefined
      });
    case types.ADDING_HELIOCORE:
      return state.merge({
        addingHeliocore: true
      });
    default:
      return state;
  }
}

export function getSelectedHelioCore(state, index) {
  return state.heliocore.heliocores.allHelioCores[index];
}
