export const LOGIN = "authentication.LOGIN";
export const LOGOUT = "authentication.LOGOUT";
export const LOGGING_IN = "authentication.LOGGING_IN";
export const CHECK_LOGIN_STATUS = "authentication.CHECK_LOGIN_STATUS";
export const LOGIN_FAILED = "authentication.LOGIN_FAILED";
export const ADD_NEW_HELIO_CORE_USER = "authentication.ADD_NEW_HELIO_CORE_USER";
export const ADDING_NEW_HELIO_CORE_USER =
  "authentication.ADDING_NEW_HELIO_CORE_USER";
export const ADDED_NEW_HELIO_CORE_USER =
  "authentication.ADDED_NEW_HELIO_CORE_USER";
export const ADD_NEW_HELIO_CORE_USER_FAILED =
  "authentication.ADD_NEW_HELIO_CORE_USER_FAILED";
export const ADD_NEW_HELIO_CLOUD_USER =
  "authentication.ADD_NEW_HELIO_CLOUD_USER";
export const ADD_NEW_HELIO_CLOUD_USER_FAILED =
  "authentication.ADD_NEW_HELIO_CLOUD_USER_FAILED";
export const GET_ADMIN_USERS = "authentication.GET_ADMIN_USERS";
export const ENTER_NEW_PASSWORD = "authentication.ENTER_NEW_PASSWORD";
export const LOGGING_IN_NEW_PASSWORD = "authentication.LOGGING_IN_NEW_PASSWORD";
export const LOGIN_NEW_PASSWORD_FAILED =
  "authentication.LOGIN_NEW_PASSWORD_FAILED";
export const GO_TO_FORGOT_PASSWORD = "authentication.GO_TO_FORGOT_PASSWORD";
export const GO_TO_LOGIN = "authentication.GO_TO_LOGIN";
export const FORGOT_PASSWORD_SUBMITTING =
  "authentication.FORGOT_PASSWORD_SUBMITTING";
export const DELETE_USER = "authentication.DELETE_USER";
export const DELETE_USER_FAILED = "authentication.DELETE_USER_FAILED";
export const DELETE_USER_DIALOG_OPEN = "authentication.DELETE_USER_DIALOG_OPEN";
export const DELETE_USER_DIALOG_CLOSE =
  "authentication.DELETE_USER_DIALOG_CLOSE";
