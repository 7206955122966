export const PUSH_PATH = "repository.PUSH_PATH";
export const POP_PATH = "repository.POP_PATH";
export const GET_REPOSITORY_FILES = "repository.GET_REPOSITORY_FILES";
export const GET_REPOSITORY_FILES_FAILED =
  "repository.GET_REPOSITORY_FILES_FAILED";
export const UPLOAD_NEW_FILES = "repository.UPLOAD_NEW_FILES";
export const UPLOAD_NEW_FILES_FAILED = "repository.UPLOAD_NEW_FILES_FAILED";
export const DELETE_FILES = "repository.DELETE_FILES";
export const DELETE_FILES_FAILED = "repository.DELETE_FILES_FAILED";
export const TOGGLE_FILE = "repository.TOGGLE_FILE";
export const CREATE_NEW_FOLDER = "repository.CREATE_NEW_FOLDER";
export const CREATE_NEW_FOLDER_FAILED = "repository.CREATE_NEW_FOLDER_FAILED";
export const OPEN_NEW_FOLDER_DIALOG = "repository.OPEN_NEW_FOLDER_DIALOG";
export const CLOSE_NEW_FOLDER_DIALOG = "repository.CLOSE_NEW_FOLDER_DIALOG";
export const COPY_FILES = "repository.COPY_FILES";
export const COPY_FILES_FAILED = "repository.COPY_FILES_FAILED";
