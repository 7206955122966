import Immutable from "seamless-immutable";
import * as types from "./actionTypes";
import * as loginStatus from "./loginStatus";

const initialState = Immutable({
  username: undefined,
  loginStatus: loginStatus.LOGGED_OUT,
  users: [],
  usersPaginationToken: undefined,
  addingNewUser: false,
  deleteUserDialogOpen: false
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGGING_IN:
      return state.merge({
        loginStatus: loginStatus.LOGGING_IN
      });
    case types.LOGIN:
      return state.merge({
        username: action.username,
        loginStatus: loginStatus.LOGGED_IN
      });
    case types.LOGIN_FAILED:
      return state.merge({
        loginStatus: loginStatus.LOGGED_OUT
      });
    case types.CHECK_LOGIN_STATUS:
      return state.merge({
        loginStatus: action.loginStatus,
        username: action.username
      });
    case types.GET_ADMIN_USERS:
      return state.merge({
        users: action.users,
        paginationToken: action.paginationToken
      });
    case types.ENTER_NEW_PASSWORD:
      return state.merge({
        loginStatus: loginStatus.ENTER_NEW_PASSWORD
      });
    case types.LOGGING_IN_NEW_PASSWORD:
      return state.merge({
        loginStatus: loginStatus.LOGGING_IN_NEW_PASSWORD
      });
    case types.LOGIN_NEW_PASSWORD_FAILED:
      return state.merge({
        loginStatus: loginStatus.ENTER_NEW_PASSWORD
      });
    case types.GO_TO_FORGOT_PASSWORD:
      return state.merge({
        loginStatus: loginStatus.FORGOT_PASSWORD
      });
    case types.GO_TO_LOGIN:
      return state.merge({
        loginStatus: loginStatus.LOGGED_OUT
      });
    case types.FORGOT_PASSWORD_SUBMITTING:
      return state.merge({
        loginStatus: loginStatus.FORGOT_PASSWORD_SUBMITTING
      });
    case types.DELETE_USER:
      return state.merge({
        users: state.users.filter(user => user.Username !== action.username)
      });
    case types.ADDING_NEW_HELIO_CORE_USER:
      return state.merge({
        addingNewUser: true
      });
    case types.ADDED_NEW_HELIO_CORE_USER:
      return state.merge({
        addingNewUser: false
      });
    case types.DELETE_USER_DIALOG_OPEN:
      return state.merge({
        deleteUserDialogOpen: true
      });
    case types.DELETE_USER_DIALOG_CLOSE:
      return state.merge({
        deleteUserDialogOpen: false
      });
    default:
      return state;
  }
}

export function getSelectedHelioCloudUser(state, index) {
  return state.authentication.users[index];
}
