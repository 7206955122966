export const GET_ALL_HELIOCORES = "heliocores.GET_ALL_HELIOCORES";
export const ADD_NEW_HELIOCORE = "heliocores.ADD_NEW_HELIOCORE";
export const UPDATE_HELIOCORE = "heliocores.UPDATE_HELIOCORE";
export const DELETE_HELIOCORE = "heliocores.DELETE_HELIOCORE";
export const TOGGLE_EDIT_MODE = "heliocores.TOGGLE_EDIT_MODE";
export const SET_EDIT_MODE_TO_FALSE = "heliocores.SET_EDIT_MODE_TO_FALSE";
export const TOGGLE_NEW_CREDENTIALS_DIALOG =
  "heliocores.TOGGLE_NEW_CREDENTIALS_DIALOG";
export const GET_NEW_HELIOCORE_CREDENTIALS =
  "heliocores.GET_NEW_HELIOCORE_CREDENTIALS";
export const DELETE_HELIOCORE_CREDENTIALS =
  "heliocores.DELETE_HELIOCORE_CREDENTIALS";
export const ADDING_HELIOCORE = "heliocores.ADDING_HELIOCORE";
export const ADDED_HELIOCORE = "heliocores.ADDED_HELIOCORE";
export const TOGGLE_DELETE_HELIOCORE_DIALOG =
  "heliocores.TOGGLE_DELETE_HELIOCORE_DIALOG";
