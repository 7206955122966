import appReducer from "./reducers";
import * as types from "./authentication/actionTypes";

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
