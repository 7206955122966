import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { awsConfig } from "./aws-config";

AWS.config.region = awsConfig.REGION;

export function login(credentials, helioCoreUser) {
  return new Promise((resolve, reject) => {
    const userPool = getUserPool();

    let authenticationData = {
      Username: credentials.username,
      Password: credentials.password
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: credentials.username,
      Pool: userPool
    };
    let cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: awsConfig.IDENTITY_POOL_ID,
          Logins: {
            [awsConfig.IDENTITY_LOGIN_ADDRESS]: result
              .getIdToken()
              .getJwtToken()
          }
        });
        cognitoUser.getUserAttributes(function(error, attributes) {
          if (error) {
          } else {
            resolve({
              username: attributes.find(attribute => attribute.Name === "email")
                .Value
            });
          }
        });
      },

      onFailure: function(error) {
        console.log(error);
        reject(error);
      },
      newPasswordRequired(userAttributes, requiredAttributes) {
        if (!credentials.newPassword) {
          resolve({ newPasswordRequired: true });
        } else {
          // the api doesn't accept this field back
          delete userAttributes.email_verified;

          let password = credentials.newPassword;

          cognitoUser.completeNewPasswordChallenge(
            password,
            userAttributes,
            this
          );
        }
      }
    });
  });
}

export function logout() {
  const userPool = getUserPool();
  let cognitoUser = userPool.getCurrentUser();
  cognitoUser.signOut();
}

export function isAuthenticated() {
  return new Promise(async (resolve, reject) => {
    try {
      const { cognitoUser, session } = await getUser();
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsConfig.IDENTITY_POOL_ID,
        Logins: {
          [awsConfig.IDENTITY_LOGIN_ADDRESS]: session.getIdToken().getJwtToken()
        }
      });

      cognitoUser.getUserAttributes(function(error, attributes) {
        if (error) {
          reject({ sessionIsValid: false, username: undefined });
        } else {
          resolve({
            sessionIsValid: session.isValid(),
            username: attributes.find(attribute => attribute.Name === "email")
              .Value
          });
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function getJWTToken() {
  return new Promise(async (resolve, reject) => {
    try {
      const { session } = await getUser();
      const jwtToken = session.getIdToken().getJwtToken();
      resolve(jwtToken);
    } catch (error) {
      reject(error);
    }
  });
}

export function addNewUser(params) {
  return new Promise(async (resolve, reject) => {
    try {
      await getUser();
      params.UserPoolId = awsConfig.USER_POOL_ID;

      let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

      cognitoIdentityServiceProvider.adminCreateUser(params, function(
        error,
        data
      ) {
        if (error) {
          reject(error);
        } else {
          resolve(data.User);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function addUserToAdminGroup(username) {
  return new Promise(async (resolve, reject) => {
    try {
      await getUser();

      let params = {
        GroupName: "Admins",
        Username: username,
        UserPoolId: awsConfig.USER_POOL_ID
      }

      let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

      cognitoIdentityServiceProvider.adminAddUserToGroup(params, function(
        error,
        data
      ) {
        if (error) {
          reject(error);
        } else {
          console.log(data);
          resolve(data);
        }
      })
    } catch (error) {
      reject(error);
    }
  })
}

export function deleteUser(params) {
  return new Promise(async (resolve, reject) => {
    try {
      await getUser();
      params.UserPoolId = awsConfig.USER_POOL_ID;

      let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

      cognitoIdentityServiceProvider.adminDeleteUser(params, function(
        error,
        data
      ) {
        if (error) {
          reject(error);
        } else {
          resolve(params);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function getAdminUsers() {
  return new Promise(async (resolve, reject) => {
    try {
      await getUser();
      const params = {
        UserPoolId: awsConfig.USER_POOL_ID,
        GroupName: "Admins"
      };
      let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

      cognitoIdentityServiceProvider.listUsersInGroup(params, function(error, data) {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function changePassword(oldPassword, newPassword) {
  return new Promise(async (resolve, reject) => {
    try {
      const { cognitoUser } = await getUser();
      cognitoUser.changePassword(oldPassword, newPassword, function(
        error,
        result
      ) {
        if (error) {
          reject(error);
        }
        resolve(result);
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function forgotPassword(username) {
  return new Promise(async (resolve, reject) => {
    try {
      const userPool = getUserPool();
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool
      });
      cognitoUser.forgotPassword({
        onSuccess: function(data) {
          // successfully initiated reset password request
          console.log("CodeDeliveryData from forgotPassword: " + data);
          resolve(username);
        },
        onFailure: function(err) {
          reject(err);
        },
        //Optional automatic callback
        inputVerificationCode: function(data) {
          console.log("Code sent to: " + data);
          var verificationCode = prompt("Please input verification code ", "");
          var newPassword = prompt("Enter new password ", "");
          cognitoUser.confirmPassword(verificationCode, newPassword, this);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function updateUserCredentials() {
  return new Promise(async (resolve, reject) => {
    try {
      await getUser();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

/*
*
*
*
*
*
*
*      NON-EXPORTED HELPER FUNCTIONS BELOW
*
*
*
*
*
*
*/

function getUser() {
  return new Promise((resolve, reject) => {
    const cognitoUser = getUserPool().getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function(error, session) {
        if (error) {
          console.log(error);
          reject({ sessionIsValid: false, username: undefined });
        }
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: awsConfig.IDENTITY_POOL_ID,
          Logins: {
            [awsConfig.IDENTITY_LOGIN_ADDRESS]: session
              .getIdToken()
              .getJwtToken()
          }
        });
        AWS.config.credentials.get(function(error) {
          if (error) {
            console.log(error);
            reject({ sessionIsValid: false, username: undefined });
          }
          resolve({ cognitoUser, session });
        });
      });
    } else {
      return reject({ sessionIsValid: false, username: undefined });
    }
  });
}

function getUserPool() {
  return new CognitoUserPool({
    UserPoolId: awsConfig.USER_POOL_ID,
    ClientId: awsConfig.CLIENT_ID
  });
}
