import Immutable from "seamless-immutable";
import * as types from "./actionTypes";

const initialState = Immutable({
  notification: {
    text: undefined
  },
  open: false
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return {
        notification: action.notification,
        open: true
      };
    case types.CLOSE_NOTIFICATION:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
}
