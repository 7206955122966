import Immutable from "seamless-immutable";
import * as types from "./actionTypes";

const initialState = Immutable({
  eolresults: []
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_EOL_RESULTS:
      return state.merge({
        eolresults: action.eolresults.Items
      });
    default:
      return state;
  }
}
