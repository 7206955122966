import * as types from "./actionTypes";
import * as notificationActions from "../notifications/actions";
import * as authService from "../../services/cognito";
import { push } from "react-router-redux";
import * as loginStatus from "./loginStatus";

export function addNewHelioCloudUser(credentials) {
  return async (dispatch, getState) => {
    try {
      let params = {
        Username: credentials.username,
        DesiredDeliveryMediums: ["EMAIL"],
        UserAttributes: [
          {
            Name: "email_verified",
            Value: "True"
          },
          {
            Name: "email",
            Value: credentials.email
          }
        ]
      };

      // First create new cognito user
      const user = await authService.addNewUser(params);
      // Then add newly created user to "Admins" group to make it admin
      await authService.addUserToAdminGroup(credentials.username);

      dispatch({ type: types.ADD_NEW_HELIO_CLOUD_USER, user: user });
      dispatch({ type: types.ADDED_NEW_HELIO_CORE_USER });
      dispatch(push("/admin/users/all"));
    } catch (error) {
      console.log(error);
      dispatch(
        notificationActions.addNotification({
          text: "Registration failed: " + error.message
        })
      );
      dispatch({ type: types.ADD_NEW_HELIO_CLOUD_USER_FAILED });
    }
  };
}

export function addingNewUser() {
  return {
    type: types.ADDING_NEW_HELIO_CORE_USER
  };
}

export function addedNewUser() {
  return {
    type: types.ADDED_NEW_HELIO_CORE_USER
  };
}

export function deleteUser(params) {
  return async (dispatch, getState) => {
    try {
      const deletedUser = await authService.deleteUser(params);
      dispatch({ type: types.DELETE_USER, username: deletedUser.Username });
    } catch (error) {
      dispatch({ type: types.DELETE_USER_FAILED });
    }
  };
}

export function deleteUserDialogOpen() {
  return {
    type: types.DELETE_USER_DIALOG_OPEN
  };
}

export function deleteUserDialogClose() {
  return {
    type: types.DELETE_USER_DIALOG_CLOSE
  };
}

export function getAdminUsers() {
  return async (dispatch, getState) => {
    try {
      const { Users, PaginationToken } = await authService.getAdminUsers();
      dispatch({
        type: types.GET_ADMIN_USERS,
        users: Users,
        paginationToken: PaginationToken
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function login(credentials) {
  return async (dispatch, getState) => {
    try {
      if (credentials.newPassword) {
        dispatch({
          type: types.LOGGING_IN_NEW_PASSWORD
        });
      } else {
        dispatch({ type: types.LOGGING_IN });
      }
      const result = await authService.login(credentials);
      if (result.newPasswordRequired) {
        dispatch({
          type: types.ENTER_NEW_PASSWORD
        });
      } else {
        dispatch({ type: types.LOGIN, username: result.username });
        dispatch(push("/admin"));
      }
    } catch (error) {
      if (credentials.newPassword) {
        dispatch({ type: types.LOGIN_NEW_PASSWORD_FAILED });
      } else {
        dispatch({ type: types.LOGIN_FAILED });
      }
      dispatch(
        notificationActions.addNotification({
          text: "Login failed: " + error.message
        })
      );
    }
  };
}

export function setLoginStatus() {
  return async (dispatch, getState) => {
    try {
      let { sessionIsValid, username } = await authService.isAuthenticated();
      const currentLoginStatus = sessionIsValid
        ? loginStatus.LOGGED_IN
        : loginStatus.LOGGED_OUT;
      dispatch({
        type: types.CHECK_LOGIN_STATUS,
        loginStatus: currentLoginStatus,
        username: username
      });
    } catch (err) {
      dispatch({
        type: types.CHECK_LOGIN_STATUS,
        loginStatus: loginStatus.LOGGED_OUT
      });
    }
  };
}

export function logout() {
  return (dispatch, getState) => {
    authService.logout();
    dispatch({
      type: types.LOGOUT,
      loginStatus: loginStatus.LOGGED_OUT,
      username: undefined
    });
    dispatch(push("/login"));
  };
}

export function changePassword(oldPassword, newPassword) {
  return async (dispatch, getState) => {
    try {
      await authService.changePassword(oldPassword, newPassword);
      dispatch(
        notificationActions.addNotification({
          text: "Password changed successfully"
        })
      );
    } catch (error) {
      dispatch(
        notificationActions.addNotification({
          text: error.message
        })
      );
    }
  };
}

export function forgotPassword(username) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.FORGOT_PASSWORD_SUBMITTING });
      const result = await authService.forgotPassword(username);
      console.log(result);
      dispatch({ type: types.GO_TO_FORGOT_PASSWORD });
      dispatch(
        notificationActions.addNotification({
          text: "Password reset for " + result
        })
      );
    } catch (error) {
      dispatch({ type: types.GO_TO_FORGOT_PASSWORD });
      console.log(error);
    }
  };
}

export function goToForgotPassword() {
  return {
    type: types.GO_TO_FORGOT_PASSWORD
  };
}

export function goToLogin() {
  return {
    type: types.GO_TO_LOGIN
  };
}
