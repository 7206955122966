import heliocore from "./heliocore/reducers";
import authentication from "./authentication/reducer";
import notifications from "./notifications/reducer";
import repository from "./repository/reducer";
import eolresults from "./eolresults/reducer";
import { reducer as form } from "redux-form";
import { combineReducers } from "redux";
import { routerReducer as router } from "react-router-redux";

const appReducer = combineReducers({
  heliocore,
  authentication,
  notifications,
  repository,
  eolresults,
  form,
  router
});

export default appReducer;
