import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import createHashHistory from "history/createHashHistory";
import registerServiceWorker from "./registerServiceWorker";
import asyncComponent from "./components/AsyncComponent";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { replace, ConnectedRouter, routerMiddleware } from "react-router-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import thunk from "redux-thunk";
import rootReducer from "./store/rootReducer";
import * as authActions from "./store/authentication/actions";
import * as loginStatus from "./store/authentication/loginStatus";
import "./index.css";

const AsyncLogin = asyncComponent(() => import("./components/Login"));
const AsyncNotFound = asyncComponent(() => import("./components/NotFound"));
const AsyncMain = asyncComponent(() => import("./components/Main"));

const history = createHashHistory();

const reduxRouterMiddleware = routerMiddleware(history);

const store = createStore(
  rootReducer,
  applyMiddleware(thunk, reduxRouterMiddleware)
);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

store.dispatch(authActions.setLoginStatus()).then(() => {
  if (store.getState().authentication.loginStatus === loginStatus.LOGGED_OUT)
    store.dispatch(replace("/login"));

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path="/admin" component={AsyncMain} />
            <Route path="/login" component={AsyncLogin} />
            <Route component={AsyncNotFound} />
          </Switch>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
  registerServiceWorker();
});
