import Immutable from "seamless-immutable";
import * as types from "./actionTypes";

const initialState = Immutable({
  files: [],
  path: "",
  checkedFiles: [],
  newFolderDialogOpen: false
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.PUSH_PATH:
      return state.merge({
        path: action.path
      });
    case types.POP_PATH:
      return state.merge({
        path: action.path
      });
    case types.OPEN_NEW_FOLDER_DIALOG:
      return state.merge({
        newFolderDialogOpen: true
      });
    case types.CLOSE_NEW_FOLDER_DIALOG:
      return state.merge({
        newFolderDialogOpen: false
      });
    case types.TOGGLE_FILE:
      return state.merge({
        checkedFiles: action.checkedFiles
      });
    case types.GET_REPOSITORY_FILES:
      return state.merge({
        files: action.files,
        checkedFiles: []
      });
    case types.COPY_FILES:
      return state.merge({
        checkedFiles: []
      });
    default:
      return state;
  }
}

export function getBreadcrumbs(state) {
  return state.repository.path.split("/").slice(0, -1);
}

export function getSortedFiles(state) {
  let collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base"
  });
  return [...state.repository.files].sort((file1, file2) => {
    return collator.compare(file1.Key, file2.Key);
  });
}
