import * as types from "./actionTypes";

export function addNotification(notification) {
  return {
    type: types.ADD_NOTIFICATION,
    notification: notification
  };
}

export function closeNotification() {
  return {
    type: types.CLOSE_NOTIFICATION
  };
}
