// prod2 config
export const awsConfig = {
  AWS_API_URL: "https://cloud-api.heliospectra.com",
  USER_POOL_ID: "eu-west-1_iQOQWv13I",
  IDENTITY_POOL_ID: "eu-west-1:9aca18c4-634d-4f97-9139-6fe7673885a9",
  IDENTITY_LOGIN_ADDRESS:
    "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_iQOQWv13I",
  CLIENT_ID: "4ummbgkgdjveb69mkid40nvbav",
  REGION: "eu-west-1",
  HELIOCORE_DEBIAN_REPOSITORY_BUCKET: "heliocoredebianrepo-prod2",
  PAGE_TITLE: "helioCLOUD"
};
